<script>
import axios from "axios"
import { ElMessage } from 'element-plus'

const axios_av = axios.create({
  baseURL: 'https://tq4yj8vx.lc-cn-n1-shared.com',
  headers: {'X-LC-Id': 'tq4yj8vxw66lgle7f691k3vk7ee6qcvtfmfzxku338hbl4kn',
            'X-LC-Key': '7ewapxqir7hvbyjqyt6hdrvg5imjqv2ltig2uac4zz5raf9c,master'}
});

export default {
  props: {
    msg: String
  },
  data() {
    return {
      phone: '',
      fullname: '',
      address: '',
      objectId: '',
      loaded: false,
      dialogVisible: false,
    }
  },
  watch: {
    phone(newPhone, oldPhone) {
      console.log(oldPhone)
      this.loaded = false
      if (newPhone.length == 11) {
        this.fetchUser()
      } else {
        this.fullname = ''
        this.address = ''
        this.objectId = ''
      }
    }
  },
  methods: {
    fetchUser() {
      axios_av
      .get(`/1.1/users?limit=1&where={"username":"${this.phone}"}`)
      .then(res => {
        let results = res.data.results
        if (results.length > 0) {
            var obj = results[0]
            this.fullname = obj.fullname
            this.address = obj.address
            this.objectId = obj.objectId
            this.loaded = true
        } else {
          this.loaded = true
        }
      })
      .catch(function () {
        this.loaded = true
      })
    },
    updateUser() {
      axios_av
      .put(`/1.1/users/${this.objectId}`,
          { 'fullname': this.fullname, 
            'address': this.address, 
            'giftConfirmed': true }
      )
      .then(() => {
        ElMessage({
          message: '提交成功，我们会尽快安排发货。',
          type: 'success',
          duration: 6000
        })
        this.dialogVisible = false
        this.phone = ''
        this.fullname = ''
        this.address = ''
        this.objectId = ''
      })
      .catch(function (error) {
        alert(error)
      })
    }
  },
  computed: {
    buttonDisabled() {
      return (this.phone.length != 11 || this.fullname.length == 0 || this.address.length == 0)
    }
  }
}
</script>

<template>
  <div>
    
    <h1>{{ msg }}</h1>
    <p style="color:#B1733A;">仅限报名者参加</p>
    <el-input v-model="phone" placeholder="请输入手机号" type="tel" style="font-size:17px; width:150px; height:45px;" input-style="text-align:center;"/>
    <p style="color:red" v-show="phone.length>11 || (phone.length==11 && objectId.length==0 && loaded==true)"><small>无匹配资料</small></p>
    <p></p>
    <el-input v-model="fullname" v-show="objectId.length>0 && phone.length==11" placeholder="姓名" style="font-size:17px; width:100px; height:45px;" input-style="text-align:center;"></el-input>
    <p></p>
    <el-input type="textarea" rows="2" v-model="address" v-show="objectId.length>0 && phone.length==11" placeholder="收货地址" style="font-size:17px; width:240px;" input-style="text-align:center; font-weight:bold; color:#5B3623"></el-input>
    <p></p>
    <el-button color="#A95E1B" :disabled="buttonDisabled" type="primary" @click="dialogVisible = true" v-show="objectId.length>0 && phone.length==11" style="font-size:19px; width:116px; height:44px;">确认地址</el-button>

    <el-dialog v-model="dialogVisible" title="确认地址" width="270px">
      <p style="font-size:16px; text-align:left; margin-left:5%; width:90%; color:#5B3623;"><strong>电话：{{phone}}</strong></p>
      <p style="font-size:16px; text-align:left; margin-left:5%; width:90%; color:#5B3623;"><strong>姓名：{{fullname}}</strong></p>
      <p style="font-size:16px; text-align:left; margin-left:5%; width:90%; color:#5B3623;"><strong>地址：{{address}}</strong></p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" style="font-size:17px; height:40px;">返回</el-button>
          <el-button color="#A95E1B" type="primary" @click="updateUser()" style="font-size:17px; height:40px;">提交</el-button>
        </span>
      </template>
    </el-dialog>
    
  </div>
</template>

<style>
.el-input {
  font-size: 16px; 
  width: 300px;
}
.el-input__inner {
  color: #5B3623;
  font-weight: bold;
}
.el-input__inner::placeholder {
  color: #E3CDB9;
  font-weight: normal;
}
.el-cascader-menu {
  min-width: 125px;
  max-width: 125px;
}
.el-button {
  margin-top: 1.3em;
}
.el-dialog__title {
  color: gray;
}
.el-dialog__header{      
  margin: 0;
}
.el-dialog__body{
  padding-top: 10px;
  padding-bottom: 0;
}
/* textarea placeholder */
textarea::-webkit-input-placeholder {
  -webkit-text-fill-color: #E3CDB9;
  font-weight: normal;
}
textarea::-moz-input-placeholder {
  -webkit-text-fill-color: #E3CDB9;
  font-weight: normal;
}
textarea::-ms-input-placeholder {
  -webkit-text-fill-color: #E3CDB9;
  font-weight: normal;
}

</style>